/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Remetente {
  constructor () {
    this.remetente_id = uuidV4()
    this.nome = ''
    this.email = ''

  }
}
