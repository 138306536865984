<template>
  <!--<span style="margin-right: 5%">-->
  <!--&lt;!&ndash;<span  :style="'color: yellow; font-size: 12px; visibility:' + (pegaTimeCount <= 60000 ? 'visible' : 'hidden')">&ndash;&gt;-->
    <!--Sua sessão expira em {{montaTexto()}}-->
  <!--</span>-->
  <div>
    <span> {{montaTexto()}} </span>
    <span v-if="pegaTimeCount <= 300000" class="renovar" @click="renovarLogin()">
      <v-icon>refresh</v-icon>
    </span>
    <span style="margin-right: 14px;"></span>
  </div>

</template>

<script>
  import {mapGetters, mapActions} from 'vuex'

  export default {
    name: 'TokenExpirationTimer',
    props: {
      // desktop: {type: Boolean, default: true},
      // vl_tab: {type: Number | String, default: 0},
      // tab: {type: Number | String, default: 0},
    },
    methods: {
      ...mapActions('geral', ['setRenovarLogin', 'setShowRenovarLoginMsg', 'setReloadPageRenovarLogin']),
      montaTexto() {
        if (this.pegaTimeCount === 0) {
          return ''
        }
        let ss = Math.floor((this.pegaTimeCount / 1000) % 60)
        let mm = Math.floor((this.pegaTimeCount / (1000 * 60)) % 60)
        let hh = Math.floor((this.pegaTimeCount / (1000 * 60 * 60)) % 24)

        hh = (hh < 10) ? '0' + hh : hh
        mm = (mm < 10) ? '0' + mm : mm
        ss = (ss < 10) ? '0' + ss : ss
        // console.clear()
        // console.log(hh + ':' + mm + ':' + ss);
        return hh + ':' + mm + ':' + ss
      },

      renovarLogin() {
        console.log('renovarLogin');
        if (this.pegaTimeCount > 0) {
          this.setShowRenovarLoginMsg(false)
          this.setReloadPageRenovarLogin(false)
        }
        this.setRenovarLogin(true)
      }
    },
    computed: {
      ...mapGetters('token', ['pegaTimeCount'])
    },
    mounted() {
    }
  }
</script>

<style scoped>
  span.renovar {
    margin-left: 6px;
  }

  span.renovar:hover {
    cursor: pointer;
  }
</style>
