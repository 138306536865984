/* eslint-disable */
import {processar} from '../../services/GenericAPI'

const url = 'disparar_email'

const actions = {

  async processarDispararMensagem({commit}, {mensagem,onSucesso, onErro}) {
    try {
      console.log('processarDispararMensagem');
      const res = await processar(url + '/commit' , mensagem, 'gravar')
      // this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso();
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      onErro()
    }
  },
}

export default {
  namespaced: true,
  actions,

}
