/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Remessa {
  constructor () {
    this.remessa_id = uuidV4()
    this.mensagem_id = ''
    this.remetente_id = ''
    this.remessas_categorias_envio = []
  }
}
