/* eslint-disable */
import {pegaDados, pegaLista, processar} from '../../services/GenericAPI'
import {Filtro} from '../models/filtro/filtro'
import {CategoriaSCH, getCategoriaSCH} from '../../search/CategoriaSCH'
import {EventBus} from '../../helpers/event-bus'
import {Categoria} from '../models/categoria'

const url = '/categoria'

const state = {
  all: [],
  allLista: [],
  filtro: new Filtro(),
  categoria: new Categoria(),
}

const getters = {
  listaCategorias: (state) => {
    return state.all
  },
  listaAllCategorias: (state) => {
    return state.allLista
  },

  pegaCategoria: (state) => {
    return state.categoria
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  async searchItem({commit}, search) {
    state.filtro.filters.addFilter('nome' ,
     "%"+search+"%",
     'like'
    )
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', CategoriaSCH(perPage))
  },
  setCategoria({commit}, categoria) {
    commit('SET_CATEGORIA', categoria)
  },
  async setAllListCategorias({commit}) {
    try {
      const list = (await pegaLista(url+'/all')).data
      commit('SET_ALL_LIST', list)
    } catch (error) {
      console.log(error);
    }
  },
  async setAllCategorias({commit},{onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data
      commit('SET_CATEGORIAS', list)
      onSucesso()
    } catch (error) {
      console.log(error);
      onErro()
    }
  },
  async processarCategoria({commit}, {categoria,acao, onSucesso, onErro}) {
    try {
      const res = await processar(url + (acao === 'gravar' ? '/commit' : ''), categoria, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso();
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      onErro()
    }
  },
}

const mutations = {
  SET_ALL_LIST (state, subgrupos) {
    state.allLista = subgrupos
  },
  SET_CATEGORIAS(state, categorias) {
    state.all = categorias
  },
  SET_CATEGORIA(state, categoria) {
    state.categoria = categoria
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
