<template>
			<div>
						<v-dialog v-model="abrir" :width="width">
									<v-card>
												<v-card-title class="dialog_titulo">Confirme</v-card-title>
												<v-card-text>
															<h3 style="color: gray">{{texto}}</h3>
												</v-card-text>
												<v-card-actions>
															<v-spacer></v-spacer>
															<v-btn id="btn_confirmar" color="green" :loading="loading">{{textOk}}</v-btn>
															<v-btn color="gray" @click="close()">{{textCancel}}</v-btn>
												</v-card-actions>
									</v-card>
						</v-dialog>
			</div>
</template>

<script>
   /* eslint-disable */

   export default {
      name: "VConfirm",
      props: {
         width: {default: '30%'},
         textOk: {default: 'Confirmar'},
         textCancel: {default: 'Fechar'},
      },
      data: () => ({
         texto: 'Mensagem',
         abrir: false,
         loading: false
      }),
      methods: {
         setTexto(texto) {
            this.texto = texto
            this.abrir = !this.abrir
            // window.confirm('asdasd');
            // var resposta = confirm("Deseja remover esse registro?");
            // if (resposta === true) {
            // }


            var _this = this
            if (this.abrir) {
               return new Promise((resolve, reject) => {
                  try {
                     var inter = setInterval(function () {
                        var btn_confirmar = document.getElementById('btn_confirmar')
                        if (btn_confirmar !== null) {
                           btn_confirmar.addEventListener('click', function () {
                              resolve()
                           })
                        }
                        clearInterval(inter)
                     }, 200)

                  } catch (e) {
                     _this.fechar()
                     reject(e)
                  }
               })
            }

         },
         // confirmar(){
         //   this.abrir = false
         // },
         close() {
            this.abrir = false
            this.loading = false
         },
      },
      components: {},
      computed: {},
      created() {
      },
      mounted() {
      },
      watch: {}
   }
</script>

<style scoped>

</style>
