/* eslint-disable */
import {pegaDados, pegaLista, processar} from '../../services/GenericAPI'
import {Filtro} from '../models/filtro/filtro'
import {EmailSCH, getEmailSCH} from '../../search/EmailSCH'
import {EventBus} from '../../helpers/event-bus'
import {Email} from '../models/email'

const url = '/email'

const state = {
  all: [],
  allLista: [],
  filtro: new Filtro(),
  email: new Email(),
}

const getters = {
  listaEmails: (state) => {
    return state.all
  },
  listaAllEmails: (state) => {
    return state.allLista
  },

  pegaEmail: (state) => {
    return state.email
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  async searchItem({commit}, search) {
    state.filtro.filters.addFilter('nome' ,
     "%"+search+"%",
     'like'
    )
  },
  async getItem({commit},{email_id, onSucesso, onErro}) {
    try {
      var filtro = getEmailSCH('email_id', email_id, '=')
      const list = (await pegaDados(url+'/get', filtro)).data
      commit('SET_EMAIL', list.data[0])
      onSucesso()
    } catch (error) {
      onErro()
    }
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', EmailSCH(perPage))
  },
  setEmail({commit}, email) {
    commit('SET_EMAIL', email)
  },
  async setAllListEmails({commit},{filtro,onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url+'/all', filtro)).data
      commit('SET_ALL_LIST', list)
      onSucesso()
    } catch (error) {
      console.log(error);
      onErro()
    }
  },
  async setAllEmails({commit},{onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data
      commit('SET_EMAILS', list)
      onSucesso()
    } catch (error) {
      console.log(error);
      onErro()
    }
  },
  async processarEmail({commit}, {email,acao, onSucesso, onErro}) {
    try {
      const res = await processar(url + (acao === 'gravar' ? '/commit' : ''), email, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso();
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      onErro()
    }
  },
}

const mutations = {
  SET_ALL_LIST (state, subgrupos) {
    state.allLista = subgrupos
  },
  SET_EMAILS(state, emails) {
    state.all = emails
  },
  SET_EMAIL(state, email) {
    state.email = email
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
