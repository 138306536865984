/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Mensagem {
  constructor () {

    this.mensagem_id = uuidV4();
    this.assunto= '';
    this.mensagem = '';
    this.imagens = '';
  }
}
