/* eslint-disable */
// import {mask} from 'vue-the-mask'
import router from '../router/router'
export default {
  // directives: {mask},
  data: () => ({

    show_list: false,
    listaItens: [],
    info_alerta: '',
    cor_alerta: 'primary',
    show_alerta: false,
    precisao: {
      decimal: ',',
      thousands: '.',
      prefix: '',
      suffix: '',
      precision: 2,
      masked: false,
      allowBlank: false,
      min: Number.MIN_SAFE_INTEGER,
      max: Number.MAX_SAFE_INTEGER
    },
  }),
  methods: {
    toDecimal(v) {
      try {
        let ret = 0;
        if (this.validarCampo(v)) {
          ret = parseFloat(parseFloat(v).toFixed(2))
        }
        return ret
      } catch (e) {
        console.log(e)
      }
    },
    itens(item) {
      this.listaItens = item
    },
    validarCampo(campo) {
      if (campo !== undefined && campo !== null && campo !== '') {
        return true
      } else {
        return false
      }
    },
    maskValor(valor, decimais, comPrefixo) {
      const numDecimais = (decimais !== null && decimais !== undefined) ? decimais : 2
      if (this.validarCampo(valor)) {
        if (comPrefixo !== null && comPrefixo !== undefined && comPrefixo) {
          return Number(valor).toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: numDecimais
          })
        } else {
          return Number(valor).toLocaleString('pt-br', {minimumFractionDigits: numDecimais})
        }
      }
      return ''
    },
    closeDialog(dialog) {
      dialog.close()
    },
    open_alerta(text, cor) {
      this.info_alerta = text
      this.cor_alerta = cor
      this.show_alerta = true
      var _this = this
      var intervalo = setInterval(function () {
        _this.show_alerta= false
        clearInterval(intervalo)
      }, 6000)
    },
     fechar_alerta() {
      this.show_alerta = false
    },
    retira_acentos(str)
    {

      var com_acento = "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ!"

      var sem_acento = "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr "
      var novastr="";
      for(var i=0; i<str.length; i++) {
        var troca=false;
        for (var a=0; a<com_acento.length; a++) {
          if (str.substr(i,1)===com_acento.substr(a,1)) {
            novastr+=sem_acento.substr(a,1);
            troca=true;
            break;
          }
        }
        if (troca===false) {
          novastr+=str.substr(i,1);
        }
      }
      return novastr;
      },
    scrollTo (id, block='start') {
      var elmnt = document.getElementById(id);
      if (this.validarCampo(elmnt)) {
        elmnt.scrollIntoView({behavior: "smooth", block: block});
      }
    },
    scrollTop (id, block='start') {
      if (this.validarCampo(id)) {
        var elmnt = document.getElementById(id);
        if (this.validarCampo(elmnt)) {
          elmnt.scrollIntoView({behavior: "smooth", block: block});
        }
      } else {
        window.scrollTo( {top: 0, left: 0, behavior: 'smooth'} );
      }
    },
    redimensionando(id){
        var teste_div = document.getElementById(id)
        var eleStyle = window.getComputedStyle(teste_div);
        var height = 760

        var hg = parseInt(eleStyle.width.replace('px', ''))-430
        if (parseInt(eleStyle.width.replace('px', ''))<=1100) {
          height = hg > 300 ? hg : 300
        } else {
          height = hg > 300 ? (hg > 760 ? 760 : hg) : 300
        }
        return height
    },
    IrParaUrl(path, target='_blank'){
      if (this.validarCampo(path)) {
        var a = document.createElement('a')
        a.href = path
        a.target = target
        a.click()
      }

    },
    zeroEsquerda(value, totalWidth, paddingChar) {
      var length = totalWidth - value.toString().length + 1
      return Array(length).join(paddingChar || '0') + value
    },
    firstWord(str) {
      var array = []
      if (this.validarCampo(str)) {
        array = str.split(" ")
        return array[0]
      } else {
        return str
      }
    },
     getDataAtual() {
        var d = this.zeroEsquerda(new Date().getDate(), 2)
        var m = this.zeroEsquerda(new Date().getMonth() + 1, 2)
        var a = new Date().getFullYear()
        return a + '-' + m + '-' + d
     },

    irPara(path, block='start') {
       var url_atual = this.retira_acentos(decodeURIComponent(window.location.pathname))

      var cortar = url_atual.replace('/', '');

      if (this.validarCampo(cortar)) {
        router.push({path:'/'}).catch(err => {
        })
        var _this = this
        var inter = setInterval(function() {
          _this.scrollTo(path, block)
          clearInterval(inter)
        },100)

      } else {
        this.scrollTo(path, block)
      }
    },
    irParaRota(path,id) {
      console.log(path);
      router.push({path:path}).catch(err => {
      })
    },
    cloneObj(obj) {
      return JSON.parse(JSON.stringify(obj))
    },
    spliter(nr, tam) {
      const chars = nr.split('');
      let temp = [];
      let parts = [];
      for (let char of chars) {
        temp.push(char);
        if (temp.length === tam) {
          parts.push(temp.join(''));
          temp = [];
        }
      }
      if (temp.length) parts.push(temp.join(''));
      return parts;
    },
    removerMascara(str) {
      if (this.validarCampo(str)) {
        return str.replace(/[^0-9]+/g, '')
      } else {
        return ''
      }
    },
    maskData(data) {
      // eslint-disable-next-line no-useless-escape,no-useless-escape, no-useless-escape, no-useless-escape
      return data.replace(/(\d{2})(\d{2})(\d{4})/g, '\$1/\$2/\$3')
    },
    reverterData(data) {
      var newData = data.split('-')
      return newData.reverse().join('')
    },
    formataData(data) {
      if (this.validarCampo(data)) {
        return this.maskData(this.reverterData(data))
      }
    },
     setarSorteioStorage(){
      /*
      * Para subir o site:
      * 1 - Configurar o sorteio_id
      * 2 - Configurar a faixa dos titulos
      * 3 - Configurar a credencial de APP do MercadoPago
      * */
        var url_atual = this.retira_acentos(decodeURIComponent(window.location.hostname))
        // sessionStorage.setItem('cnpj_cpf', url_atual === 'localhost' ? '72099059012' : '97085683090')
        /* santuario administrador_id cartela: ff687168-db5a-477f-9705-585e62375790 */

        sessionStorage.setItem('sorteio_id_previa', url_atual === 'localhost' ? '2f8da531-f237-4da4-96f3-03a1d6fd29ca' : '16436ed4-a97e-4366-98aa-444012e0f9c3') // Santuario (beta e cartela)
        sessionStorage.setItem('sorteio_id', url_atual === 'localhost' ? '2f8da531-f237-4da4-96f3-03a1d6fd29ca' : '16436ed4-a97e-4366-98aa-444012e0f9c3') // Santuario (beta e cartela)

     },
    cortarString(str, tam) {
      return this.validarCampo(str) ? (str.slice(0, tam) + (tam > str.length ? '' : '...')) : str
    },
    getValueByKey (item,key) {
      if (item[key] === undefined) {
        return item
      } else {
        return item[key]
      }
    },
    logs(obj){
      if (this.validarCampo(obj)) {
        return JSON.parse(JSON.stringify(obj))
      } else {
        return obj
      }
    },
    toArray(str){
      if (this.validarCampo(sessionStorage.getItem(str))) {
        return JSON.parse(sessionStorage.getItem(str))
      } else {
        return str
      }
    }
  },
}
