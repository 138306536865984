/* eslint-disable */
import {pegaDados, pegaLista, processar} from '../../services/GenericAPI'
import {Filtro} from '../models/filtro/filtro'
import {RemessaSCH, getRemessaSCH} from '../../search/RemessaSCH'
import {EventBus} from '../../helpers/event-bus'
import {Remessa} from '../models/remessa'

const url = '/remessa'

const state = {
  all: [],
  allLista: [],
  filtro: new Filtro(),
  remessa: new Remessa(),
}

const getters = {
  listaRemessas: (state) => {
    return state.all
  },
  listaAllRemessas: (state) => {
    return state.allLista
  },

  pegaRemessa: (state) => {
    return state.remessa
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  async searchItem({commit}, search) {
    state.filtro.filters.addFilter('nome' ,
     "%"+search+"%",
     'like'
    )
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', RemessaSCH(perPage))
  },
  setRemessa({commit}, remessa) {
    commit('SET_REMESSA', remessa)
  },
  async setAllListRemessas({commit},{onSucesso, onErro}) {
    try {
      const list = (await pegaLista(url+'/all')).data
      commit('SET_ALL_LIST', list)
      onSucesso()
    } catch (error) {
      console.log(error);
      onErro()
    }
  },
  async setAllRemessas({commit},{onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data
      commit('SET_REMESSAS', list)
      onSucesso()
    } catch (error) {
      console.log(error);
      onErro()
    }
  },
  async processarRemessa({commit}, {remessa,acao, onSucesso, onErro}) {
    try {
      const res = await processar(url + (acao === 'gravar' ? '/commit' : ''), remessa, acao)
      // this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso();
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      onErro()
    }
  },
}

const mutations = {
  SET_ALL_LIST (state, subgrupos) {
    state.allLista = subgrupos
  },
  SET_REMESSAS(state, remessas) {
    state.all = remessas
  },
  SET_REMESSA(state, remessa) {
    state.remessa = remessa
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
