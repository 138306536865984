/* eslint-disable */
import {createStore} from 'vuex'
import geral from './modules/geral'
import emails from "./modules/emails";
import categorias from "./modules/categorias";
import mensagens from "./modules/mensagens";
import usuarios from "./modules/usuarios";
import disparar_mensagens from "./modules/disparar_mensagens";
import remetentes from "./modules/remetentes";
import token from "./modules/token";
import remessas from "./modules/remessas";

export default createStore({
   modules: {
      token,
      geral,
      emails,
      categorias,
      mensagens,
      usuarios,
      remetentes,
      remessas,
      disparar_mensagens
   }
})

