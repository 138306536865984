/* eslint-disable */
import {pegaDados, pegaLista, processar} from '../../services/GenericAPI'
import {Filtro} from '../models/filtro/filtro'
import {MensagemSCH, getMensagemSCH} from '../../search/MensagemSCH'
import {EventBus} from '../../helpers/event-bus'
import {Mensagem} from '../models/mensagem'
import {getEmailSCH} from "../../search/EmailSCH";

const url = '/mensagem'

const state = {
  all: [],
  allLista: [],
  filtro: new Filtro(),
  mensagem: new Mensagem(),
}

const getters = {
  listaMensagens: (state) => {
    return state.all
  },
  listaAllMensagens: (state) => {
    return state.allLista
  },

  pegaMensagem: (state) => {
    return state.mensagem
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  async setAllListMensagens({commit}) {
    try {
      const list = (await pegaLista(url+'/all')).data
      commit('SET_ALL_LIST', list)
    } catch (error) {
      console.log(error);
    }
  },

  async getItem({commit},{mensagem_id, onSucesso, onErro}) {
    try {
      var filtro = getMensagemSCH('mensagem_id', mensagem_id, '=')
      const list = (await pegaDados(url+'/get', filtro)).data
      commit('SET_MENSAGEM', list.data[0])
      onSucesso()
    } catch (error) {
      onErro()
    }
  },
  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', MensagemSCH(perPage))
  },
  setMensagem({commit}, mensagem) {
    commit('SET_MENSAGEM', mensagem)
  },
  async setAllMensagens({commit},{onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data
      commit('SET_MENSAGENS', list)
      onSucesso()
    } catch (error) {
      console.log(error);
      onErro()
    }

  },
  async processarMensagem({commit}, {mensagem,acao, onSucesso, onErro}) {
    try {
      const res = await processar(url + (acao === 'gravar' ? '/commit' : ''), mensagem, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso();
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      onErro()
    }
  },
}

const mutations = {
  SET_ALL_LIST (state, subgrupos) {
    state.allLista = subgrupos
  },
  SET_MENSAGENS(state, mensagems) {
    state.all = mensagems
  },
  SET_MENSAGEM(state, mensagem) {
    state.mensagem = mensagem
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
