/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Email {
  constructor () {

    this.email_id = uuidV4();
    this.nome = '';
    this.email = '';
    this.emails_categorias = [];
  }
}
