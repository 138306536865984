/* eslint-disable */
import {pegaDados, pegaLista, processar} from '../../services/GenericAPI'
import {Filtro} from '../models/filtro/filtro'
import {RemetenteSCH, getRemetenteSCH} from '../../search/RemetenteSCH'
import {EventBus} from '../../helpers/event-bus'
import {Remetente} from '../models/remetente'

const url = '/remetente'

const state = {
  all: [],
  allLista: [],
  filtro: new Filtro(),
  remetente: new Remetente(),
}

const getters = {
  listaRemetentes: (state) => {
    return state.all
  },
  listaAllRemetentes: (state) => {
    return state.allLista
  },

  pegaRemetente: (state) => {
    return state.remetente
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  async searchItem({commit}, search) {
    state.filtro.filters.addFilter('nome' ,
     "%"+search+"%",
     'like'
    )
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', RemetenteSCH(perPage))
  },
  setRemetente({commit}, remetente) {
    commit('SET_REMETENTE', remetente)
  },
  async setAllListRemetentes({commit}) {
    try {
      const list = (await pegaLista(url+'/all')).data
      commit('SET_ALL_LIST', list)

    } catch (error) {
      console.log(error);

    }
  },
  async setAllRemetentes({commit},{onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data
      commit('SET_REMETENTES', list)
      onSucesso()
    } catch (error) {
      console.log(error);
      onErro()
    }
  },
  async processarRemetente({commit}, {remetente,acao, onSucesso, onErro}) {
    try {
      const res = await processar(url + (acao === 'gravar' ? '/commit' : ''), remetente, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso();
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      onErro()
    }
  },
}

const mutations = {
  SET_ALL_LIST (state, subgrupos) {
    state.allLista = subgrupos
  },
  SET_REMETENTES(state, remetentes) {
    state.all = remetentes
  },
  SET_REMETENTE(state, remetente) {
    state.remetente = remetente
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
