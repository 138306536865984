import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store'

import './assets/main.scss'
import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'
import VueTheMask  from 'vue-the-mask'
import VConfirm from "./components/VConfirm";

import {sync} from 'vuex-router-sync'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import ProAlerta from './components/Alerta.vue'
import TableIterator from "./components/TableIterator.vue";
import ModalCadastro from "./components/ModalCadastro.vue";
import PForm from "./components/PForm.vue";
import {createVuetify} from "vuetify";
// import Dialog from 'vue3-dialog';



const vuetify = createVuetify({
   components,
   directives,
})


const app = createApp(App)
sync(store, router)
app.use(VueTheMask)
app.use(router)
app.use(vuetify)
app.use(store)


app.component('v-confirm', VConfirm)
app.component('pro-alerta', ProAlerta)
app.component('table-iterator', TableIterator)
app.component('pro-modal', ModalCadastro)
app.component('p-form', PForm)

app.mount('#app')
