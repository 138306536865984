/* eslint-disable */
import {pegaDados, pegaLista, processar, setarToken} from '../../services/GenericAPI'
import {Filtro, Where} from "../models/filtro/filtro";
import {UsuarioSCH} from "../../search/UsuarioSCH"
import {EventBus} from '../../helpers/event-bus'
import {Usuario} from "../models/usuario";

const url = 'usuario'

const state = {
   all: [],
   usuario: new Usuario(),
   filtro: new Filtro()
}

const getters = {

   listaUsuarios: (state) => {
      return state.all
   },
   pegaUsuario: (state) => {
      return state.usuario
   },
   pegaFiltro: (state) => {
      return state.filtro
   }
}

const actions = {
   searchItem({commit}, search) {
      state.filtro.filters.addSearch(['nome', 'email'],
       "%" + search + "%",
       'ilike'
      )
   },

   setUsuario({commit}, usuario) {
      commit('SET_USUARIO', usuario)
   },

   setFiltro({commit}, perPage) {
      commit('SET_FILTRO', UsuarioSCH(perPage))
   },

   async setAllUsuarios({commit}, {onSucesso}) {
      try {
         const list = (await pegaDados(url + '/get', state.filtro)).data
         commit('SET_USUARIOS', list)
         onSucesso();
      } catch (error) {
      }
   },
   async autenticaUsuarios({commit}, {usuario, onSucesso, onErro}) {
      try {
         const res = (await processar(url + '/auth', usuario, 'gravar'))
         sessionStorage.setItem('usuario', res.data.usuario.nome)
         // this.dispatch('geral/setMsgSuccess', res.data.message)

         setarToken(res.data.token, res.data.usuario.usuario_id)
         sessionStorage.setItem('token', res.data.token)
         sessionStorage.setItem('token_expiration', res.data.token_expiration * 60 * 1000)

         this.dispatch('token/cancelarToken')
         this.dispatch('token/start', null)
         onSucesso();
      } catch (error) {
         console.log(error);
         this.dispatch('geral/setObjMsgError', error.response.data)
         onErro()
      }
   },
   async processarUsuario({commit}, {usuario, acao, onSucesso, onErro}) {
      try {
         console.log(usuario);
         const res = await processar(url + (acao === 'gravar' ? '/commit' : ''), usuario, acao)
         this.dispatch('geral/setMsgSuccess', res.data.message)
         onSucesso();
      } catch (error) {
         this.dispatch('geral/setObjMsgError', error.response.data)
         onErro()
      }
   },
   async processarSenha({commit}, {usuario, onSucesso, onErro}) {
      try {
         const res = await processar(url + '/esqueceu_senha', usuario, 'gravar')
         this.dispatch('geral/setMsgSuccess', res.data.message)
         onSucesso();
      } catch (error) {
         this.dispatch('geral/setObjMsgError', error.response.data)
         onErro()
      }
   },
}

const mutations = {
   SET_USUARIOS(state, usuarios) {
      state.all = usuarios
   },
   SET_USUARIO(state, usuario) {
      state.usuario = usuario
   },
   SET_FILTRO(state, filtro) {
      state.filtro = filtro
   }
}

export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations
}
