/* eslint-disable */
import {Filtro} from "../store/models/filtro/filtro";


export const MensagemSCH = (perPage)=> {
    var filtro = new Filtro()
    filtro.perPage = perPage
    filtro.fields = ['*']
    filtro.addOrder('assunto', 'asc')
  return filtro
}


export const  getMensagemSCH = (campo, valor, condicao)=>{
  var filtro = new Filtro()
  filtro.perPage = 1
  filtro.filters.addFilter(campo,valor,condicao)
  return filtro
}

