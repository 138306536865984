import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  // history: createWebHistory(import.meta.env.BASE_URL),
  history: createWebHistory(process.env.BASE_URL),
  routes: [
     {path: '/', name: 'Login',component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')},

     {
      path: '/dash',
      name: 'Dash',
      component: () => import(/* webpackChunkName: "Dash" */ '../views/Dash.vue'),
      children: [
        {path: '/home', name: 'Home',component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue')},
        {path: '/email', name: 'Email',component: () => import(/* webpackChunkName: "Email" */ '../views/Email/Email.vue')},
        {path: '/categoria', name: 'Categoria',component: () => import(/* webpackChunkName: "Categoria" */ '../views/Categoria/Categoria.vue')},
        {path: '/mensagem', name: 'Mensagem',component: () => import(/* webpackChunkName: "Mensagem" */ '../views/Mensagem/Mensagem.vue')},
        {path: '/mensagem/cadastro', name: 'Cadastro de Mensagem',component: () => import(/* webpackChunkName: "MensagemCadastro" */ '../views/Mensagem/MensagemCadastro.vue')},
        {path: '/disparar_email', name: 'Disparar Email',component: () => import(/* webpackChunkName: "DispararEmail" */ '../views/DispararEmail/DispararEmail.vue')},
        {path: '/usuario', name: 'Usuario',component: () => import(/* webpackChunkName: "Usuario" */ '../views/Usuario/Usuario.vue')},
        {path: '/remetente', name: 'Remetente',component: () => import(/* webpackChunkName: "Remetente" */ '../views/Remetente/Remetente.vue')},

      ]
    },
     { path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: () => import(/* webpackChunkName: "404" */ '../views/NotFound.vue')
     },
  ]
})

export default router
